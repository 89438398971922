import React from "react"
import ThemeProvider from "./src/components/layout/theme"
import "./src/styles/global.css"
import "./src/styles/fontawesome/css/all.min.css"

export const wrapPageElement = ({ element }) => {
  return (
    <ThemeProvider>
      {element}
    </ThemeProvider>
  )
}