import React from "react"
import ThemeToggler from "./common/themeToggler"
import * as styles from "./navbar.module.css"

const Navbar = (props) => {
  const {isDarkModeActive, changeTheme} = props

  return (
    <div className={styles.container}>
      <section>
        <div>ABBAGREEN ENGINEERING</div>
        <ThemeToggler isDarkModeActive={isDarkModeActive} changeTheme={changeTheme} />
      </section>
    </div>
   )
}

export default Navbar