import React from "react"
import { Helmet } from "react-helmet"
import Navbar from "../navbar"
import * as styles from "./theme.module.css"

export const ThemeContext = React.createContext("light")

const ThemeProvider = (props) => {
  const [isDarkMode, setDarkMode] = React.useState(false)

  return (
    <ThemeContext.Provider value={isDarkMode ? "dark" : "light"}>
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href="https://fonts.googleapis.com/css2?family=Roboto" rel="stylesheet"></link>
      </Helmet>
      <Navbar isDarkModeActive={isDarkMode} changeTheme={() => setDarkMode(!isDarkMode)} />
      <div className={isDarkMode ? styles.dark : styles.light}>
        {props.children}
      </div>
    </ThemeContext.Provider>
  )
}

export default ThemeProvider